/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import theme from './src/theme/theme';
import client from './src/gatsby-theme-apollo/client';

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={client}>
		<ThemeProvider theme={theme}>{element}</ThemeProvider>
	</ApolloProvider>
);
